/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "c-salyg1n-and-the-mex-game",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#c-salyg1n-and-the-mex-game",
    "aria-label": "c salyg1n and the mex game permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "C. Salyg1n and the MEX Game"), "\n", React.createElement(_components.p, null, "and solution of this problem with proof in Rust Language"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "In this article, we will see my solution to the codeforces problem, ", React.createElement(_components.a, {
    href: "https://codeforces.com/contest/1867/problem/C"
  }, "1867C. Salyg1n and the MEX Game"), ", which came in Codeforces Round 897 (Div. 2)."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "This is an interactive problem!")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " My Solution might not be the most optimized one, but it is certainly working."), "\n", React.createElement(_components.p, null, "You can go to above link to view the question statement."), "\n", React.createElement(_components.h2, {
    id: "approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#approach",
    "aria-label": "approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Approach"), "\n", React.createElement(_components.p, null, "In this problem, we can see that we can each time add a number, and a number less than that will be removed in next turn, until we add minimum number."), "\n", React.createElement(_components.p, null, "So, we have to add ", React.createElement(_components.strong, null, "the mex of the array"), " each time, till we reach the 0."), "\n", React.createElement(_components.h2, {
    id: "proof",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#proof",
    "aria-label": "proof permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Proof"), "\n", React.createElement(_components.p, null, "Let us suppose to the contradiction that adding mex is not the optimal solution. This means that we can add ", React.createElement(_components.code, null, "x"), " that can become mex, also there is an element ", React.createElement(_components.code, null, "y"), " which is current mex."), "\n", React.createElement(_components.p, null, "Then, Bob can remove an element from the array, such that there are 2 elements less than ", React.createElement(_components.code, null, "x"), " missing from the array."), "\n", React.createElement(_components.p, null, "As Alice can only add 1 element at a time, after which, 1 element will be removed from the array, so net change in elements less than ", React.createElement(_components.code, null, "x"), " is at max 1."), "\n", React.createElement(_components.p, null, "Hence, we can say that there is always a missing element less than ", React.createElement(_components.code, null, "x"), ", so mex will always be less than ", React.createElement(_components.code, null, "x"), "."), "\n", React.createElement(_components.p, null, "But this contradicts our assumption."), "\n", React.createElement(_components.p, null, "Hence, we have to add mex each time."), "\n", React.createElement(_components.p, null, "Hence Proved."), "\n", React.createElement(_components.h2, {
    id: "implementation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#implementation",
    "aria-label": "implementation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Implementation"), "\n", React.createElement(_components.p, null, "We first find the mex of the array. Then we output it. Now, we take the input and print it, till -1, because the element removed will be new mex."), "\n", React.createElement(_components.h2, {
    id: "program",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program",
    "aria-label": "program permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program"), "\n", React.createElement(_components.p, null, "Program using above implementation is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "/*\nThis template is made by Naman Garg <naman.rustp@gmail.com>\nGitHub : https://github.com/namanlp\nGitLab : https://gitlab.com/namanlp\nWebsite : https://rustp.org\n\nYou can visit https://rustp.org/basic-programs/basic-template/\nfor understanding the template\n\nFeel free to copy the template, but not the solutions :D\nThank You\n */\n\n#![allow(unused)]\n\nuse std::io::stdin;\n\nfn take_int() -> i128 {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace().map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\n// Find Mex function\n\nfn find_mex(arr:&Vec<usize>) ->usize{\n    // If missing element is less than largest element\n    for i in 0..arr.len() { if arr[i]!=i { return i;} }\n\n    // Else, if all elements present upto n-1, return n\n    // For example array is 0, 1, 2, return 3\n    return arr.len();\n}\n\nfn solve() {\n// ======================= Code Here =========================\n    let _n = take_int();\n    let mut arr = take_vector();\n\n    // Sort the vector and find the mex\n    arr.sort();\n    let mex = find_mex(&arr) as i128;\n\n    println!(\"{}\", mex);\n    let mut choice = take_int();\n\n    // Print the input, till we reach -1\n    while choice >= 0 {\n        println!(\"{}\", choice);\n        choice = take_int();\n    }\n}\n\npub fn main() {\n    let t = take_int();\n    for _ in 0..t { solve(); }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In this article, we discussed solution to Codeforces problem 1867C. Salyg1n and the MEX Game along with proof and program in Rust Language."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Codeforces 1867C solution with proof - Rust Programming",
    description: "In this article, we will discuss solution to Codeforces problem 1867C. Salyg1n and the MEX Game along with proof.",
    img: "https://rustp.org/Static_Images_DND/Social/Codeforces/Codeforces_1867C_social.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
